<template>
  <div class="invite">
    <div class="head">
      <img @click="back" src="@/assets/images/back.png" class="arrow_img" />
      <span>公告详情</span>
    </div>
    <div class="box">
      <div class="title">
        <span> {{ dataObj.title }} </span>
      </div>
      <div class="time">
        <span>{{ dateFormatFn(dataObj.createtime) }}</span>
      </div>
      <div class="content">
        <div v-if="dataObj.img_url">
          <img :src="baseUrl + dataObj.img_url" class="image" alt="" />
        </div>
        <div class="">
          <span v-html="dataObj.body"></span>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { homeApi } from '@/api/index';
import { dateFormat } from '@/utils/formatTool.js';
export default {
  data() {
    return {
      dataObj: {
        title: undefined,
        create_time: undefined,
        body: undefined,
      },
      baseUrl: 'https://api.gnamege.com',
    };
  },
  mounted() {
    // this.dataObj = ;
    this.getData(this.$route.query.id);
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    dateFormatFn(date) {
      if (date) {
        return dateFormat(new Date(date * 1000), 'yyyy-MM-dd');
      }
    },
    getData(id) {
      let params = {
        id,
      };
      homeApi.getAnnouncementDetail(params).then(res => {
        if (res.code == 200) {
          this.dataObj = res.data;
        }
      });
    },
  },
};
</script>
  
<style lang="less" scoped>
.invite {
  min-height: 100vh;
  padding-top: 50px;
  .head {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(#000, 0.8);
    letter-spacing: 1.5px;
    font-weight: 550;
    background: #fff;
    padding: 14px 0;
    .arrow_img {
      position: absolute;
      left: 20px;
      width: 10px;
      height: 16px;
    }
  }
  .box {
    border-radius: 16px;
    margin: 16px;
    color: #000;
    font-size: 13px;
    padding: 16px;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
    background-color: rgba(255, 255, 255, 1);
    // .flex {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    // }

    .title {
      font-weight: 550;
      // text-align: center;
      color: rgba(#000, 0.86);
      font-size: 16px;
      padding-bottom: 16px;
    }
    .time {
      text-align: right;
      padding-bottom: 16px;
    }
    .content {
      font-size: 13px;
      color: #000;
      padding-top: 16px;
      border-top: 1px solid rgba(232, 240, 247, 1);
      .image {
        width: 100%;
      }
    }
  }
}
</style>