<template>
  <div class="mine">
    <div class="banner">
      <div class="flex-row">
        <div class="block_1">
          <img src="@/assets/logo.jpg" class="avatar" />
        </div>
        <div class="flex-col">
          <span class="text_1">{{ userinfo.nickname }}</span>
          <div class="flex-row">
            <span class="text_2">{{ userinfo.addressEncrypt }}</span>
            <div class="text-wrapper_2">
              <span class="copys" @click="copy" :data-clipboard-text="this.userinfo.address">复制</span>
            </div>
          </div>
          <div class="flex-row">
            <span class="text_2">{{ userinfo.team_level_name || '' }}</span>
            <!-- <div class="text-wrapper_1" v-if="userinfo.submit_limit == 1" @click="reckoningClick('partition', '')">
              <span>申请分区</span>
            </div> -->
          </div>
        </div>
      </div>
      <div class="grade" @click="reckoningClick('personal', '')">
        <img src="@/assets/images/right-black.png" class="grade_img" />
      </div>
    </div>
    <div class="content">
      <div class="balance_box">
        <div class="balance_li" @click="reckoningClick('assetDetails', { coin_type: 'usdt' })">
          <div class="left">
            <span class="amount">{{ Math.floor(userinfo.usdt * 100) / 100 || 0 }}</span>
          </div>
          <div class="right">
            <img src="@/assets/images/usdt.png" class="icon1" />
            <span>USDT</span>
          </div>
        </div>
        <div class="balance_li" @click="reckoningClick('assetDetails', { coin_type: 'power', coin_name: '算力' })">
          <div class="left">
            <span class="amount">{{ Math.floor(userinfo.power * 100) / 100 || 0 }}</span>
          </div>
          <div class="right">
            <img src="@/assets/images/mine.png" class="icon2" />
            <span>算力</span>
          </div>
        </div>
      </div>
      <!-- <div class="order">
        <div class="top">
          <span class="order_text1">订单</span>
          <span class="order_text2" @click="reckoningClick('orders', '')">全部订单 ></span>
        </div>
        <div class="bottom flex-row">
          <div class="item flex-col" @click="reckoningClick('orders', { status_id: 1 })">
            <span class="num">{{ order_count.pay || 0 }}</span>
            <span>待支付</span>
          </div>
          <div class="item flex-col" @click="reckoningClick('orders', { status_id: 2 })">
            <span class="num">{{ order_count.pay || 0 }}</span>
            <span>待出售</span>
          </div>
          <div class="item flex-col" @click="reckoningClick('orders', { status_id: 3 })">
            <span class="num">{{ order_count.sell || 0 }}</span>
            <span>出售中</span>
          </div>
          <div class="item flex-col" @click="reckoningClick('orders', { status_id: 4 })">
            <span class="num">{{ order_count.success || 0 }}</span>
            <span>已完成</span>
          </div>
          <div class="item flex-col" @click="reckoningClick('orders', { status_id: 5 })">
            <span class="num">{{ order_count.cancel || 0 }}</span>
            <span>已失效</span>
          </div>
        </div>
      </div> -->
      <div class="list">
        <div v-for="(item, index) of list" :key="index" @click="switchovers(item)" :style="{ 'border-top': index != 0 ? ' 1px solid rgba(232, 240, 247, 1)' : '' }" class="li">
          <div class="li_left">
            <img :src="item.image" class="li_img" />
            <span>{{ item.name }}</span>
          </div>
          <img src="@/assets/images/right-white.png" class="right" />
        </div>
      </div>
    </div>
    <!-- 上级弹窗 -->
    <van-popup v-model="superiorShow" round style="width: 80%">
      <Superior :address="userinfo.parent_address" :level="userinfo.team_level_name" :parantId="userinfo.parent_id"></Superior>
    </van-popup>
    <BottomNavigation></BottomNavigation>
  </div>
</template>

<script>
import Superior from './superiorPopup.vue';
import { Notify } from 'vant';
import { usApi, newsLetterApi, walletApi, homeApi } from '@/api/index';
import { mapState } from 'vuex';
import union from '../../api/union';
import BottomNavigation from '@/components/BottomNavigation.vue';
import Clipboard from 'clipboard';
import Web3 from 'web3';
export default {
  data() {
    return {
      show: false, //上级弹窗状态
      withdrawShow: false, //提现弹窗状态
      list: [
        {
          image: require('@/assets/images/link.png'),
          name: '邀请链接',
          router: 'invite',
        },
        {
          image: require('@/assets/images/team.png'),
          name: '我的团队',
          router: 'team',
        },
        {
          image: require('@/assets/images/record.png'),
          name: '订单记录',
          router: 'orders',
        },
        {
          image: require('@/assets/images/record.png'),
          name: '提现记录',
          router: 'withdrawRecord',
        },

        {
          image: require('@/assets/images/money.png'),
          name: '我的上级',
          router: '',
        },
      ],
      total: 0,
      team_level_name: '',
      userinfo: {
        address: undefined,
        nickname: undefined,
        avatar: undefined,
        team_level_id: undefined,
        seso_url: undefined,
        submit_limit: 0,
      },
      order_count: {
        pay: 0,
        sell: 0,
        success: 0,
        cancel: 0,
      },
      withdrawAmount: 0, // 提现金额
      feeObj: {
        usdt: 0,
        withdraw_fee_rate: 0,
        withdraw_min_amount: 0,
      },
      receiptAmount: 0, // 实际到账金额
      superiorShow: false, //上级弹窗
    };
  },
  created() {
    this.init();
  },
  mounted() {
    // this.init();
  },
  methods: {
    init() {
      if (this.account) {
        this.userinfo = JSON.parse(localStorage.getItem('userinfo'));
        this.userinfo.addressEncrypt = this.userinfo.address.substring(0, 5) + '****' + this.userinfo.address.substring(this.userinfo.address.length - 5, this.userinfo.address.length);
        this.getUserInfo();
      }
    },

    switchovers(item) {
      console.log(item.router);
      if (item.router == '') {
        // window.location.href = this.userinfo.seso_url;
        this.superiorShow = true;
      } else {
        this.$router.push({ name: item.router });
      }
    },
    reckoningClick(name, query) {
      this.$router.push({ name: name, query: query });
    },
    getUserInfo() {
      homeApi.getUserInfo({}).then(res => {
        if (res.code == 200) {
          this.userinfo = res.data;
          this.order_count = res.data.order_count;
          this.userinfo.addressEncrypt = this.userinfo.address.substring(0, 5) + '****' + this.userinfo.address.substring(this.userinfo.address.length - 5, this.userinfo.address.length);
        }
      });
    },
    copy() {
      if (this.userinfo.address) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast('复制成功');
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast('复制失败,浏览器不支持');
          clipboard.destroy(); // 释放内存
        });
      }
    },
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    account() {
      this.init();
    },
    withdrawAmount(e) {
      // this.receiptAmount = 0;
      this.receiptAmount = Number(e) - Number(e) * Number(this.feeObj.withdraw_fee_rate);
    },
  },
  components: {
    Superior,
    BottomNavigation,
  },
};
</script>

<style lang="less" scoped>
.mine {
  min-height: 100vh;
  .banner {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(to bottom right, #7f84f4, #dae9fd);
    padding: 40px 16px 80px;
    .block_1 {
      box-shadow: 0px 0px 29px 0px rgba(97, 142, 184, 0.19);
      background-color: rgba(169, 191, 245, 1);
      border-radius: 50%;
      width: 68px;
      height: 68px;
      border: 3px solid rgba(#b6b3b3, 1);
      margin-right: 14px;
      overflow: hidden;
      .avatar {
        width: 100%;
        height: 100%;
      }
    }

    .grade {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fff;
      .grade_img {
        display: block;
        width: 7px;
        height: 12px;
      }
    }
    .text_1 {
      color: rgba(6, 12, 80, 1);
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 4px;
    }
    .text_2 {
      color: rgba(#000, 0.6);
      font-size: 13px;
    }
    .text-wrapper_1 {
      font-size: 11px;
      line-height: 100%;
      padding: 5px 10px;
      background-color: rgba(246, 162, 15, 1);
      border-radius: 6px;
      margin: 6px;
    }
    .text-wrapper_2 {
      font-size: 11px;
      line-height: 100%;
      padding: 5px 10px;
      background-color: rgb(15, 142, 246);
      border-radius: 6px;
      margin: 6px;
    }
  }
  .flex-col {
    display: flex;
    flex-direction: column;
  }
  .flex-row {
    display: flex;
    align-items: center;
  }
  .content {
    padding: 0 16px 100px;
    margin-top: -40px;
    color: #000;
    .balance_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .balance_li {
        display: flex;
        flex-direction: column;
        width: 48%;
        font-size: 14px;
        padding: 14px;
        box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
        border-radius: 16px;
        background: #fff;

        .left {
          display: flex;
          flex-direction: column;
        }
        .icon1 {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
        .icon2 {
          width: 16px;
          height: 14px;
          margin-right: 4px;
        }
        .right {
          display: flex;
          align-items: center;
          color: rgba(92, 112, 157, 1);
          font-size: 13px;
          margin-top: 6px;
        }
        .btn {
          color: rgba(48, 70, 212, 1);
          font-size: 12px;
          padding: 4px 8px;
          border-radius: 3px;
          margin-left: 8px;
          background-color: rgba(48, 70, 212, 0.1);
        }
        .amount {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .order {
      font-size: 13px;
      color: rgba(153, 153, 153, 1);
      border-radius: 16px;
      padding: 16px;
      box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
      margin-top: 16px;
      background-color: rgba(255, 255, 255, 1);
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(232, 240, 247, 1);
        .order_text1 {
          font-size: 16px;
          color: rgba(0, 0, 0, 1);
          font-weight: 600;
        }
        .order_text2 {
          font-size: 12px;
        }
      }
      .bottom {
        padding-top: 16px;
        justify-content: space-between;
        .item {
          align-items: center;
          text-align: center;
          .num {
            color: rgba(#000, 0.75);
            font-size: 16px;
            font-weight: 650;
            padding-bottom: 4px;
          }
        }
      }
    }
    .list {
      border-radius: 16px;
      padding: 0 16px;
      margin-top: 16px;
      box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
      background-color: rgba(255, 255, 255, 1);
      .li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: rgba(51, 51, 51, 1);
        padding: 16px 0;
        .li_left {
          display: flex;
          align-items: center;
          .li_img {
            display: block;
            width: 30px;
            height: 30px;
            margin-right: 6px;
          }
        }
        .right {
          width: 7px;
          height: 12px;
        }
      }
    }
  }

  .withdrawPopup {
    padding: 20px 30px;
    color: #666666;
    font-size: 14px;
    .title {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      font-weight: bold;
      font-size: 16px;
      color: #000000;
    }

    .tip {
      font-size: 12px;
      display: flex;
      flex-direction: column;
    }
    .btn {
      color: #fff;
      padding: 16px 0;
      text-align: center;
      line-height: 50%;
      border-radius: 20px;
      margin-top: 18px;
      background-color: #238be8;
    }
    .close—icon {
      position: absolute;
      bottom: -40px;
      left: 45%;
    }
  }
}
/deep/ .van-popup {
  overflow-y: visible;
}

/deep/ .van-field {
  background-color: #f2f9f9;
  border-radius: 8px;
  margin: 6px 0;
}
/deep/ .van-field__body {
  padding: 10px 10px;
}
</style>