<template>
  <div class="trade">
    <div class="head">
      <!-- <img @click="back" src="@/assets/images/back.png" class="arrow_img" /> -->
      <span>{{ tabList[tabIndex] }}</span>
    </div>
    <div class="tab">
      <div class="tab_item" :class="tabIndex == index ? 'tab_active' : ''" v-for="(item, index) in tabList" :key="index" @click="changeTab1(index)">
        <span>{{ item }}</span>
      </div>
    </div>
    <!-- 产品 -->
    <template v-if="tabIndex == 0">
      <div class="title">
        <span>产品购买</span>
      </div>
      <div class="reserve">
        <div class="flex" style="margin: 0 0 12px">
          <div>
            <span>钱包额度: </span>
            <span class="text2">{{ walletBalance || 0 }} U</span>
          </div>
        </div>
        <div class="productItem" v-for="(item, index) in productsList" :key="index">
          <div class="flex1">
            <div class="service_img">
              <img :src="item.img_url" alt="" />
            </div>
            <div>
              <div style="margin: 12px 0">
                <span class="text1">{{ item.title }}</span>
              </div>
              <div style="margin: 12px 0">
                <span>价格 </span>
                <span class="text1">{{ Math.floor(item.price * 100) / 100 || 0 }} USDT</span>
              </div>
              <div style="margin: 12px 0">
                <span>赠送算力 </span>
                <span class="text1">{{ Math.floor(item.power * 100) / 100 || 0 }} T</span>
              </div>
            </div>
          </div>
          <div class="btn_box flex-end">
            <div :class="item.power == 0 ? 'btn2' : 'btn3'" @click="createOrder(item, 1)">
              <span>{{ item.power == 0 ? '复购' : '立即购买' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="title">
        <span>购买记录</span>
      </div>
      <template v-if="showLoading">
        <div style="width: 100%">
          <van-loading style="margin-left: calc(50% - 10px)" />
        </div>
      </template>
      <template v-else-if="!showLoading && serversBuyRecord.length < 1">
        <div style="text-align: center; color: #000; padding-top: 16px">
          <span>暂无数据</span>
        </div>
      </template>
      <template v-else>
        <div class="today_reserve" v-for="(item, index) in serversBuyRecord" :key="index">
          <div class="top flex">
            <span class="top_text1">{{ item.pro_name }}</span>
            <span class="top_text2">{{ dateFormatFn(item.create_time) }}</span>
          </div>
          <div class="box_4"></div>
          <div class="flex" style="padding: 6px 0 6px">
            <div class="flex-col">
              <div style="padding-bottom: 12px">
                <span>价格</span>
                <span class="text3">{{ Math.floor(item.amount * 100) / 100 }} U</span>
              </div>
              <div>
                <span>算力</span>
                <span class="text3">{{ Math.floor(item.power * 100) / 100 }} T</span>
              </div>
            </div>
            <div class="flex-col" v-if="item.status === 0" @click="payOrder(item)">
              <div class="status status4">
                <span>{{ item.status_name }}</span>
              </div>
            </div>
            <div class="flex-col" v-else>
              <div class="status status1">
                <span>{{ item.status_name }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <!-- 服务器购买 -->
    <template v-else>
      <div class="title">
        <span>服务器购买</span>
      </div>
      <div class="reserve">
        <div class="flex" style="margin: 0 0 12px">
          <div>
            <span>钱包额度: </span>
            <span class="text2">{{ walletBalance || 0 }} U</span>
          </div>
        </div>
        <div class="productItem" v-for="(item, index) in serversList" :key="index">
          <div class="flex1">
            <div class="service_img">
              <img src="@/assets/images/trade/introduce_img.png" alt="" />
            </div>
            <div>
              <div style="margin: 12px 0">
                <span class="text1">{{ item.title }}</span>
              </div>
              <div style="margin: 12px 0">
                <span>价格 </span>
                <span class="text1">{{ Math.floor(item.price * 100) / 100 || 0 }} USDT</span>
              </div>
              <div style="margin: 12px 0">
                <span>算力 </span>
                <span class="text1">{{ Math.floor(item.power * 100) / 100 || 0 }} T</span>
              </div>
            </div>
          </div>
          <div class="btn_box flex-end">
            <div class="btn3" @click="createOrder(item, 2)">
              <span>立即购买</span>
            </div>
          </div>
        </div>
      </div>
      <div class="title">
        <span>购买记录</span>
      </div>
      <template v-if="showLoading">
        <div style="width: 100%">
          <van-loading style="margin-left: calc(50% - 10px)" />
        </div>
      </template>
      <template v-else-if="!showLoading && serversBuyRecord.length < 1">
        <div style="text-align: center; color: #000; padding-top: 16px">
          <span>暂无数据</span>
        </div>
      </template>
      <template v-else>
        <div class="today_reserve" v-for="(item, index) in serversBuyRecord" :key="index">
          <div class="top flex">
            <span class="top_text1">{{ item.pro_name }}</span>
            <span class="top_text2">{{ dateFormatFn(item.create_time) }}</span>
          </div>
          <div class="box_4"></div>
          <div class="flex" style="padding: 6px 0 6px">
            <div class="flex-col">
              <div style="padding-bottom: 12px">
                <span>支付金额</span>
                <span class="text3">{{ Math.floor(item.amount * 100) / 100 }} U</span>
              </div>
              <div>
                <span>算力</span>
                <span class="text3">{{ Math.floor(item.power * 100) / 100 }} T</span>
              </div>
            </div>
            <div class="flex-col" v-if="item.status === 0" @click="payOrder(item)">
              <div class="status status4">
                <span>{{ item.status_name }}</span>
              </div>
            </div>
            <div class="flex-col" v-else>
              <div class="status status1">
                <span>{{ item.status_name }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <!-- 加载弹窗 -->
    <van-popup v-model="isApprove" round style="width: 60%" :close-on-click-overlay="false">
      <div class="bind_box">
        <div style="font-weight: bold"><van-loading type="spinner" /></div>
        <div class="address_field">处理中...</div>
      </div>
    </van-popup>
    <BottomNavigation></BottomNavigation>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Web3 from 'web3';
import Clipboard from 'clipboard';
import BottomNavigation from '@/components/BottomNavigation.vue';
import { getErc20Contract } from '@/utils/contractHelp';
import { formatAmount } from '@/utils/format.js';
import { homeApi } from '@/api/index';
import { dateFormat } from '@/utils/formatTool.js';
import packApi from '@/methods/pack.js';
import { Toast } from 'vant';
import usdt from '@/methods/usdt.js';
export default {
  data() {
    return {
      tabList: ['产品', '服务器'],
      // tabList2: ['钱包余额', '系统余额', '报单算力余额'],
      tabIndex: 0,
      // tabIndex2: 0,
      images: [require('@/assets/images/trade/banner.png'), require('@/assets/images/trade/banner.png')],
      time: 0,
      walletBalance: 0, // 钱包余额
      payToken: undefined, //支付币种
      showLoading: true, //购买
      isApprove: false, // 控制加载弹窗显示隐藏
      serversList: [],
      productsList: [],
      serversBuyRecord: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.account) {
        this.getProductsList();
        this.serversInfo();
        this.grtServersRecord();
        this.getPayToken(); //获取支付币种
      }
    },
    //返回上一页
    back() {
      this.$router.back();
    },
    changeTab1(index) {
      this.tabIndex = index;
    },
    changeTab2(index) {
      this.tabIndex2 = index;
    },
    dateFormatFn(date) {
      return dateFormat(new Date(date * 1000), 'yyyy-MM-dd hh:mm:ss');
    },
    getPayToken() {
      packApi.getPayToken(this.account).then(res => {
        this.payToken = res;
        // this.handleApproveBtnShow(); //获取授权余额
        this.getWalletBalance(); //获取钱包余额
      });
    },
    // 查询链上钱包余额
    async getWalletBalance() {
      const erc20Contract = getErc20Contract(this.payToken);
      let bal = await erc20Contract.methods.balanceOf(this.account).call();
      this.walletBalance = (formatAmount(bal, 18) * 1).toFixed(2);
    },
    // 复制
    copy() {
      var clipboard = new Clipboard('.copys');
      clipboard.on('success', e => {
        this.$toast(this.$t('lang.swap222'));
        clipboard.destroy(); // 释放内存
      });
      clipboard.on('error', e => {
        this.$toast(this.$t('lang.swap223'));
        clipboard.destroy(); // 释放内存
      });
    },

    getProductsList() {
      homeApi.productsList().then(res => {
        if (res.code == 200) {
          this.productsList = res.data;
        }
      });
    },
    serversInfo() {
      homeApi.serversInfo().then(res => {
        if (res.code == 200) {
          this.serversList = res.data.info;
        }
      });
    },
    // 服务器购买记录
    grtServersRecord() {
      homeApi
        .serversList()
        .then(res => {
          this.showLoading = false;
          if (res.code == 200) {
            this.serversBuyRecord = res.data;
          }
        })
        .catch(err => {
          this.showLoading = false;
          this.serversBuyRecord = [];
        });
    },

    // 下单
    createOrder(item, type) {
      // type:1产品 2服务器
      console.log(item);
      if (this.walletBalance * 1 < item.price * 1) {
        this.$toast('余额不足');
        return;
      }
      this.isApprove = true;
      let params = {
        id: item.id,
        type: type,
      };
      homeApi
        .createOrder(params)
        .then(res => {
          if (res.code == 200) {
            let obj = res.data;
            // 链上转账
            this.payMethod(obj, type);
          }
        })
        .catch(err => {
          this.isApprove = false;
        });
    },
    // 待支付订单重新支付
    payOrder(item) {
      console.log(item);
      this.isApprove = true;
      let params = { order_no: item.order_no, order_type: item.order_type };
      homeApi
        .getOrder(params)
        .then(res => {
          if (res.code == 200) {
            if (res.data.amount > this.walletBalance * 1) {
              this.$toast('余额不足');
              this.isApprove = false;
              return;
            } else {
              this.payMethod(res.data, item.order_type);
            }
          } else {
            console.log(111);
            this.isApprove = false;
            this.$toast(res.msg);
            return;
          }
        })
        .catch(err => {
          this.isApprove = false;
          this.$toast(err.msg);
          return;
        });
    },
    payMethod(obj, type) {
      usdt
        .transfer(this.account, obj.pay_address, obj.amount, this.payToken)
        .then(res => {
          console.log(res);
          // 调回调接口
          let params = {
            amount: obj.amount,
            type: type,
            order_no: obj.order_no,
            tx_hash: res,
          };
          homeApi
            .updateOrder(params)
            .then(res => {
              this.isApprove = false;
              if (res.code == 200) {
                Toast({ message: `${res.msg}` });
                this.grtServersRecord();
              }
            })
            .catch(err => {
              this.grtServersRecord();
              this.$toast(err.msg);
              this.isApprove = false;
              return;
            });
        })
        .catch(err => {
          console.log(11111111111);
          this.grtServersRecord();
          this.$toast(err.message);
          this.isApprove = false;
          return;
        });
    },
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    account() {
      this.init();
    },
  },
  components: {
    BottomNavigation,
  },
};
</script>

<style lang="less" scoped>
.head {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(#000, 0.8);
  letter-spacing: 1.5px;
  font-weight: 550;
  background: #fff;
  padding: 14px 0;
  z-index: 99;
  .arrow_img {
    position: absolute;
    left: 20px;
    width: 10px;
    height: 16px;
  }
}
.trade {
  padding: 50px 16px 160px;
  min-height: 100vh;

  .tab {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 20px 0 0;
    background-color: #fff;
    border-radius: 40px;
    padding: 4px;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
    &_item {
      text-align: center;
      color: #000;
      width: 50%;
    }
    &_active {
      color: #fff;
      font-weight: 700;
      padding: 10px 0;
      background-color: #4159d5;
      border-radius: 40px;
    }
    &_item1 {
      text-align: center;
      color: #000;
      width: 33%;
    }
    &_active1 {
      color: #fff;
      font-weight: 700;
      padding: 10px 0;
      background-color: #4159d5;
      border-radius: 40px;
    }
  }
  .banner {
    color: #fff;
    font-size: 22px;
    border-radius: 30px;
    // font-weight: bold;
    // background: url('~@/assets/image/banner.png') no-repeat top/100% 100%;
    // padding: 40px 0 0 20px;
    margin-top: 20px;
    .my-swipe {
      width: 100%;
      height: 155px;
      border-radius: 20px;
      box-sizing: border-box;
      .swipe-item {
        height: 100%;
        img {
          height: 155px;
        }
      }
    }
  }
  .countdown {
    font-size: 14px;
    font-weight: 600;
    padding: 16px;
    margin-top: 20px;
    background: url('~@/assets/images/index/countdown_bg.png') no-repeat top/100% 100%;

    .text {
      display: flex;
      justify-content: space-between;
      .text1 {
        color: rgba(#ffffff, 0.8);
        font-size: 12px;
        font-weight: normal;
      }
    }
    .time {
      padding-top: 10px;
      .colon {
        display: inline-block;
        margin: 0 4px;
        color: #fff;
      }
      .block {
        display: inline-block;
        // width: 22px;
        color: #3f43df;
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        padding: 2px 8px;
        border-radius: 3px;
        background-color: #ffffff;
      }
    }
  }
  .title {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
    padding: 16px 0;
  }
  .reserve {
    color: rgba(153, 153, 153, 1);
    font-size: 14px;
    letter-spacing: 0.8px;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 15px;

    .productItem {
      padding: 30px 0;
      border-bottom: 1px solid rgb(230, 233, 236);
    }
    .productItem:last-child {
      border-bottom: 0px solid rgb(230, 233, 236);
    }
    .text1 {
      color: rgba(34, 35, 64, 1);
      font-size: 16px;
      font-weight: 700;
    }
    .text2 {
      color: rgba(65, 89, 213, 1);
      font-size: 16px;
      font-weight: 700;
    }

    .input_box {
      display: flex;
      align-items: center;
      margin: 12px 0;
      font-size: 14px;
      .input {
        color: #000;
        height: 36px;
        width: 250px;
        padding: 10px;
        border: none;
        border-radius: 8px;
        margin-right: 10px;
        background-color: rgba(236, 244, 245, 1);
      }
    }
    .btn_box {
      font-size: 14px;
      text-align: center;
      padding-top: 10px;
      justify-content: center;
      .btn1 {
        height: 40px;
        width: 90px;
        line-height: 40px;
        color: rgba(65, 89, 213, 1);
        border-radius: 20px;
        background-color: rgba(232, 241, 253, 1);
      }
      .btn2 {
        height: 40px;
        width: 90px;
        line-height: 40px;
        color: rgba(65, 89, 213, 1);
        border-radius: 20px;
        border: 1px solid rgba(65, 89, 213, 1);
        background-color: #fff;
      }
      .btn3 {
        height: 40px;
        width: 90px;
        color: #fff;
        line-height: 40px;
        border-radius: 20px;
        background-color: rgba(65, 89, 213, 1);
        // margin-left: 20px;
      }
    }
  }
  .box_4 {
    background-color: rgb(224, 226, 228);
    width: 100%;
    box-sizing: border-box;
    height: 1px;
    margin: 20px 0 10px;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .flex1 {
    display: flex;
    align-items: center;
    .service_img {
      padding-right: 16px;
      img {
        width: 100px;
        height: 90px;
        border-radius: 16px;
      }
    }
  }
  .flex-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flex-end {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .flex-col {
    display: flex;
    // align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .today_reserve {
    color: rgba(153, 153, 153, 1);
    font-size: 14px;
    padding: 16px;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
    border-radius: 15px;
    margin-bottom: 15px;
    background-color: rgba(255, 255, 255, 0.3);
    .top {
      // padding-bottom: 20px;
      &_text1 {
        font-size: 15px;
        color: rgba(51, 51, 51, 1);
        // font-weight: 550;
      }
      &_text2 {
        font-size: 12px;
        color: rgba(153, 153, 153, 1);
      }
    }
    .text3 {
      color: rgba(#000, 1);
      padding-left: 6px;
    }
    .status {
      padding: 6px 16px;
      font-size: 12px;
      border-radius: 30px;
    }
    .status1 {
      color: rgba(16, 209, 188, 1);
      background-color: rgba(224, 242, 240, 1);
      border-radius: 30px;
    }
    .status2 {
      color: rgba(229, 92, 41, 1);
      background-color: rgba(250, 234, 228, 1);
      display: flex;
      align-items: center;
      .countdownstatus2 {
        font-size: 12px;
        color: rgba(229, 92, 41, 1);
      }
    }
    .status4 {
      color: rgba(216, 176, 24, 1);
      font-size: 12px;
      padding: 6px 16px;
      background-color: rgba(247, 243, 228, 1);
      border-radius: 30px;
    }
    .status3 {
      color: rgba(122, 153, 153, 1);
      background-color: rgba(224, 240, 240, 1);
    }
    .status5 {
      color: rgba(65, 89, 213, 1);
      background-color: rgba(228, 234, 247, 1);
    }
  }
}
input::-webkit-input-placeholder {
  /* 使用webkit内核的浏览器 */
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
}
input:-moz-placeholder {
  /* Firefox版本4-18 */
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
}
input::-moz-placeholder {
  /* Firefox版本19+ */
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
}
input:-ms-input-placeholder {
  /* IE浏览器 */
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
}
.bind_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  background-color: #000;
  .address_field {
    padding-top: 16px;
  }
}
</style>