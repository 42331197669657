export const lang = {
  swap1: '输出为估值，您将获得至少{inputOutMin} {symbol},或者交易将被撤',
  swap2: '输入为估值，您将出售最多{inputInMax} {symbol},或者交易将被撤回',
  swap3: '输出为估值。如果价格变化超过 {slippage}%，则您的交易将被撤回',
  swap4: '输出为估值。如果价格变化超过 {slippage}%，则您的交易将被撤回',
  swap5: '确认交换',
  swap6: '正在等待确认',
  swap7: '正在将',
  swap8: '交换为',
  swap9: '在你的钱包种确认此交易',
  swap10: '已提交交易',
  swap11: '在BscScan上查看',
  swap12: '将Swan-LP添加到Metamsak',
  swap13: '交易拒绝',
  swap14: '关闭',
  swap15: '正在移除',
  swap16: '和',
  swap17: '正在供应',
  swap18: '兑换',
  swap19: '流动性',
  swap20: '从',
  swap21: '估计',
  swap22: '余额',
  swap23: '最大',
  swap24: '选择通证',
  swap25: '到',
  swap26: '连接钱包',
  swap27: '此交易的流动性不足',
  swap28: '输入数量',
  swap29: '余额不足',
  swap30: '选择代币',
  swap31: '授权',
  swap32: '包装',
  swap33: '解包',
  swap34: '价格影响过高',
  swap35: '闪兑',
  swap36: '价格',
  swap37: '滑点容差',
  swap38: '价格影响',
  swap39: '路由',
  swap40: '最小获得量',
  swap41: '流动性供应商费用',
  swap42: '交易收据',
  swap43: '闪对',
  swap44: '您将获得',
  swap45: '最大出售量',
  swap46: '此交换的价格影响至少为 10%,请确认此交换',
  swap47: '确认',
  swap48: '取消',
  swap49: '最近交易',
  swap50: '全部清除',
  swap51: '最近没有交易',
  swap52: '您的流动性',
  swap53: '已入池',
  swap54: '添加',
  swap55: '移除',
  swap56: '增加流动性',
  swap57: '没有看到您加入的池？',
  swap58: '导入它',
  swap59: '如果您将XXX代币质押在农场中，请在此处取消质押以查看它们',
  swap60: '您是第一个流动性供应商。',
  swap61: '您添加代币的比列，将决定该流动性池的初始价格。',
  swap62: '如果您对汇率满意，请点击“供应”以进行检查。',
  swap63: '输入',
  swap64: '兑换率和流动池份额',
  swap65: '流动性池中的份额',
  swap66: '供应',
  swap67: '接收',
  swap68: '金额',
  swap69: '你将获得',
  swap70: '您的钱包中的LP代币',
  swap71: '移除流动性以收回代币',
  swap72: '导入池',
  swap73: '选择代币以查找您的流动性。',
  swap74: '您在该流动性池中还没有提供流动性。',
  swap75: '已入金',
  swap76: '汇率',
  swap77: '确认供应',
  swap78: '已焚毁',
  swap79: '资产变更',
  swap80: '提币账单',
  swap81: '提取数量',
  swap82: '手续费',
  swap83: '实际到账',
  swap84: '已通过',
  swap85: '已驳回',
  swap86: '我要买',
  swap87: '我要卖',
  swap88: '订单',
  swap89: '需求发布',
  swap90: '单价',
  swap91: '管理代币',
  swap92: '导入代币',
  swap93: '搜索名称或粘贴地址',
  swap94: '导入',
  swap95: '管理Tokens',
  swap96: '自定义代币',
  swap97: '首页',
  swap98: '交易',
  swap99: '资讯',
  swap100: '社区',
  swap101: '我的',
  swap102: '未连接',
  swap103: '申请中',
  swap104: '进入',
  swap105: '行情展示',
  swap106: '币种',
  swap107: '涨跌幅',
  swap108: '蝴蝶部落是⼀群有共同兴趣、认知、价值观的社区，联合在一起互动、交流、协作、感染而产⽣的新的信任和价值链接平台，由这种建⽴在社群之间的情感关联、信任产生新的部落型社区。区块链时代的来临让社群发挥了难以想象的⼒量，通过社群和社群组织之间更有效率的连接和价值交换，形成一个⾼效率且高效益的全新运转经济体。',
  swap109: '暂未开放',
  swap110: '蝴蝶社区',
  swap111: 'SER & SES 双币联动 · 轮回重生即将开启权益认筹',
  swap112: '社区公告',
  swap113: '联系人',
  swap114: '消息',
  swap115: '圈子',
  swap116: '搜索联系人',
  swap117: '暂无联系人',
  swap118: '暂无数据',
  swap119: '屏蔽该用户',
  swap120: '举报该内容',
  swap121: '快转',
  swap122: '引用',
  swap123: '分享',
  swap124: '新的朋友',
  swap125: '群组',
  swap126: '频道',
  swap127: '附近的人和群',
  swap128: '发表',
  swap129: '请输入发表内容',
  swap130: '只接受 JPG、BMP、PNG、GIF 格式',
  swap131: '上传中...',
  swap132: '上传成功',
  swap133: '上传失败',
  swap134: '请输入内容',
  swap135: '请添加图片',
  swap136: '表布成功',
  swap137: '深度',
  swap138: '快讯',
  swap139: '闪兑账单',
  swap140: '当前余额不足',
  swap141: '请选择币种',
  swap142: '兑换成功',
  swap143: '已赚取',
  swap144: '年化利率',
  swap145: '收割',
  swap146: '质押',
  swap147: '进行中',
  swap148: '已结束',
  swap149: '我参与的',
  swap150: '质押lp代币',
  swap151: '赎回lp代币',
  swap152: '赎回',
  swap153: '确实',
  swap154: '分享链接',
  swap155: '新手资料',
  swap156: '更多',
  swap157: '发图素材',
  swap158: '常用工具',
  swap159: '常见问题',
  swap160: '市场机制',
  swap161: '发展规划',
  swap162: '上一张',
  swap163: '下一张',
  swap164: '发圈素材',
  swap165: '发圈海报',
  swap166: '发圈视频',
  swap167: '发圈文案',
  swap168: '市场活动',
  swap169: '节日祝福',
  swap170: '好友邀请',
  swap171: '项目介绍',
  swap172: '全部',
  swap173: '转入',
  swap174: '转出',
  swap175: '转账',
  swap176: '收款',
  swap177: '请输入转账数量',
  swap178: '请输入接收人地址',
  swap179: '转账成功',
  swap180: '接收人',
  swap181: '数量',
  swap182: '可转',
  swap183: '请选择',
  swap184: '请输入数量',
  swap185: '最大转账',
  swap186: '最小转账',
  swap187: '交易值扣除',
  swap188: '手续费扣减',
  swap189: '请输入要修改的昵称',
  swap190: '保存',
  swap191: '请先上传图片',
  swap192: '修改成功',
  swap193: '我要反馈',
  swap194: '回复',
  swap195: '反馈标题',
  swap196: '标题',
  swap197: '请填写标题',
  swap198: '反馈内容',
  swap199: '请填写内容',
  swap200: '附件',
  swap201: '提交',
  swap202: '暂无回复',
  swap203: '提交成功',
  swap204: '您的问题',
  swap205: '您在使用app过程中遇见什么问题，或者对平台有什么建议都可以写在这里哦',
  swap206: '请填写回复内容',
  swap207: '蝴蝶学院',
  swap208: '邀请好友',
  swap209: '星级中心',
  swap210: '资产',
  swap211: '可用',
  swap212: '冻结',
  swap213: '贡献值',
  swap214: '查看矿池',
  swap215: '资产明细',
  swap216: '请先链接账号',
  swap217: '去邀请',
  swap218: '我的推荐人',
  swap219: '暂无上级',
  swap220: '我的推荐',
  swap221: '暂无推荐',
  swap222: '复制成功',
  swap223: '不支持复制,该浏览器不支持自动复制',
  swap224: '划转',
  swap225: '持有矿池',
  swap226: '领取',
  swap227: '阅读',
  swap228: '成长区',
  swap229: '赠送区',
  swap230: '首发中心',
  swap231: '蝴蝶部落是⼀群有共同兴趣、认知、价值观的社区，联合在一起互动、交流、协作、感染而产⽣的新的信任和价值链接平台，由这种建⽴在社群之间的情感关联、信任产生新的部落型社区。区块链时代的来临让社群发挥了难以想象的⼒量，通过社群和社群组织之间更有效率的连接和价值交换，形成一个⾼效率且高效益的全新运转经济体。',
  swap232: '当前已完成进度百分比',
  swap233: '会超过100%',
  swap234: '总需捐献',
  swap235: '您已捐献',
  swap236: '说明',
  swap237: '本次xxxx领袖招募信息由xxxx提供并负责',
  swap238: '本次招募可超额进行，由xxxx社区进行考核，未予通过的领袖席位将自动退回捐献资金、',
  swap239: '会员',
  swap240: '联合合伙人',
  swap241: '股东合伙人',
  swap242: '恭喜您，您已获得',
  swap243: '列表',
  swap244: '进行中',
  swap245: '已完成',
  swap246: '次数',
  swap247: '总产能',
  swap248: '号矿池',
  swap249: '号赠送',
  swap250: '总奖励',
  swap251: '消耗',
  swap252: '完成次数',
  swap253: '已奖励',
  swap254: '剩余次数',
  swap255: '充值',
  swap256: '提现',
  swap257: '复制',
  swap258: '请输入充值数量',
  swap259: '请输入提现数量',
  swap260: '平台余额',
  swap261: '充值成功',
  swap262: '转账失败',
  swap263: '提现数量不可超过可用余额',
  swap264: '当前不可提币',
  swap265: '超过当前最大可提币量',
  swap266: '提币成功',
  swap267: '总资产',
  swap268: '代币',
  swap269: '成交',
  swap270: '成交率',
  swap271: '限额',
  swap272: '去购买',
  swap273: '去出售',
  swap274: '请输入购买数量',
  swap275: '购买数量须在限额内',
  swap276: '超过余额',
  swap277: '购买成功',
  swap278: '出售成功',
  swap279: '我的订单',
  swap280: '可用余额',
  swap281: '请输入出售数量',
  swap282: '剩余可成交',
  swap283: '立即购买',
  swap284: '立即出售',
  swap285: '我的发布',
  swap286: '发布订单',
  swap287: '发布类型',
  swap288: '购买',
  swap289: '出售',
  swap290: '支付方式',
  swap291: '请选择支付方式',
  swap292: '请输入单价',
  swap293: '发布数量',
  swap294: '请输入发布数量',
  swap295: '选择币种',
  swap296: '最大限额',
  swap297: '请输入最大限额',
  swap298: '最小限额',
  swap299: '请输入最小限额',
  swap300: '发布',
  swap301: '输入最大价格应为',
  swap302: '最小价格应为',
  swap303: '输入最大数量应为',
  swap304: '最小数量应为',
  swap305: '买',
  swap306: '卖',
  swap307: '订单号',
  swap308: '取消订单',
  swap309: '撤销成功',
  swap310: '单价不在范围内',
  swap311: '数量不在范围内',
  swap312: '请选择发布币种',
  swap313: '请选择最小限额',
  swap314: '请选择最大限额',
  swap315: '最小限额不得大于最大限额',
  swap316: '最大限额不能超过发布数量',
  swap317: '购买价格不能超过余额',
  swap318: '发布数量不能超过余额',
  swap319: '发布成功',
  swap320: '提币',
  swap321: '你已加入',
  swap322: '加入',
  swap323: '请先登录',
  swap324: '授权失败',
  swap325: '加入成功',
  swap326: '实际兑换数量',
  swap327: '收到数量',
  swap328: '请输入兑换数量',
  swap329: '请先添加邀请人',
  swap330: '升级',
  swap331: 'SER是DEFI领域基于DAO+ iGaming 3.0的生态型代币，子币SES。通过全新的双币联动的市场推动计划，给予用户娱乐性体验的同时保障价值的持续稳定增长。浴火涅槃、轮回重生、生生不息，价值不灭，SER权益认筹启动中！',
  swap332: '我的奖励',
  swap333: '推荐列表',
  swap334: '地址',
  swap335: '确定将',
  swap336: '划转到资产吗',
  swap337: '矿池账户',
  swap338: '钱包账户',
  swap339: '贡献值余额',
  swap340: '消耗贡献值',
  swap341: '确认划转',
  swap342: '增加贡献值',
  swap343: '转入成功',
  swap344: '贡献值不足',
  swap345: '转出成功',
  swap346: '当前暂无可用余额',
  swap347: '已超过最大持有',
  swap348: '即将开放',
  swap349: '需支付',
  swap350: '将得到',
  swap351: '邀请奖励计划',
  swap352: '邀请用户共同助力Nebula基础设施建设，成为Nebula共同建设者，即可获得多重收益。',
  swap353: '我的邀请链接',
  swap354: '矿机列表',
  swap355: '持有列表',
  swap356: '持有数量',
  swap357: '总产出',
  swap358: '今日产出',
  swap359: '产出进度',
  swap360: '矿机',
  swap361: '预计产出',
  swap362: '充值数量',
  swap363: '对方账户',
  swap364: '请输入对方账户',
  swap365: '转账数量',
  swap366: '转账说明',
  swap367: '内部互转不走区块，不扣手续费，即时到账，不可逆请仔细核对对方账户。',
  swap368: '需要转到其他平台请通过提币方式',
  swap369: '超过可成交数',
  swap370: '盲盒',
  swap371: '我的资产',
  swap372: '账单列表',
  swap373: '请绑定邀请人',
  swap374: '发单总价需大于30USDT',
  swap375: '超出了钱包余额',
  swap376: '入账中，请稍后查看',
  swap377: '新用户优惠',
  swap378: '----首次注册即可获赠价值10 USDT HM',
  swap379: '挂单',
  swap380: '钱包地址',
  swap381: '挖矿',
  swap382: '分红',

  swap383: '公告',
  swap384: '暂无公告',
  swap385: '账单',
  swap386: '奖励类型',
  swap387: '邀请得丰厚奖励',
  swap388: '我的团队',
  swap389: '充值资金',
  swap390: '提取资金',
  swap391: 'NFT背包',
  swap392: '用户反馈',
  swap393: '关于我们',
  swap394: '仪表盘',
  swap395: '挖矿中',
  swap396: '算力',
  swap397: '取回',
  swap398: '平台算力',
  swap399: '我的算力',
  swap400: '团队算力',
  swap401: 'NFT市场',
  swap402: '售价',
  swap403: '开始',
  swap404: '我的碎片',
  swap405: '合成',
  swap406: '说明：点击碎片，满五个即可开启合成，随机获得 一张NFT或者一个碎片',
  swap407: '合成中',
  swap408: '赠送',
  swap409: '合成成功',
  swap410: '请输入赠送地址',
  swap411: '说明：赠送后无法撤销',
  swap412: '确定',
  swap413: '购买中',
  swap414: '赠送成功',
  swap415: '碎片数量不足',
  swap416: '歌曲展示',
  swap417: '展示',
  swap418: '简介',
  swap419: '合作伙伴',
  swap420: '我的等级',
  swap421: '当前等级',
  swap422: '团队业绩',
  swap423: '达标部门',
  swap424: '好友邀请计划',
  swap425: '邀请好友加入SESO社区，一起赚取丰厚奖励！ 如果您邀请的朋友参与游戏，您将获得多重收益',
  swap426: '团队人数',
  swap427: '复制邀请',
  swap428: '业绩',
  swap429: '兑换数量',
  swap430: '反馈列表',
  swap431: '暂无反馈',
  swap432: '用户',
  swap433: '线上客服',
  swap434: '暂无答复',
  swap435: '添加图片（最多三张）',
  swap436: '请输入留言',
  swap437: '反馈',
  swap438: '请上传图片',
  swap439: '请输入反馈内容',
  swap440: '已持有',
  swap441: '邀请',
  swap442: '推荐链接',
  swap443: '请绑定领袖',
  swap444: '了解',
  swap445: '治理',
  swap446: '竞猜',
  swap447: '请先绑定邀请人',
  swap448: '取消成功',
  swap449: '类型',
  swap450: '市场',
  swap451: '授权中',
  swap452: '重置',
  swap453: '碎片',
  swap454: '购买类型',
  swap455: '平台公告',
  swap456: '收货地址',
  swap457: 'FULL OPEN简介',
  swap458: '<br><br><br>',
  swap459: '权益池',
  swap460: '超级合伙人招募',
  swap461: '会员招募',
  swap462: '超级合伙人权益',
  swap463: '手续费累计奖励',
  swap464: '社区累计奖励',
  swap465: '手续费分红',
  swap466: 'NFT数藏',
  swap467: '链游大厅',
  swap468: '匿名社交',
  swap469: 'AI云计算',
  swap470: '会员招募计划',
  swap471: '塔苏大老窖（9~10年珍藏）',
  swap472: '逸春风牡蛎酒1062',
  swap473: '购买份数',
  swap474: '超级合伙人招募计划',
  swap475: '逸春风519.逸春风重阳酒各一支',
  swap476: '收货人',
  swap477: '手机号码',
  swap478: '所在地区',
  swap479: '所在地区(详细)',
  swap480: '请输入收货人',
  swap481: '请输入手机号码',
  swap482: '请输入所在地区',
  swap483: '请输入详细地区',
  swap484: '下一等级达标条件',
  swap485: '请先购买',
  swap486: '无',
  swap487: '今日业绩',
  swap488: '大厅',
  swap489: '奖励进度',
  swap490: '奖励',
  swap491: '发行量',
  swap492: '开始时间',
  swap493: '结束时间',
  swap494: '我的资金',
  swap495: '我的藏品',
  swap496: '敬请期待',
  swap497: '暂无购买资格',
  swap498: '平台简介',
  swap499: '发展',
  swap500: 'NFT',
  swap501: '发展',
  swap502: 'FULL OPEN简称FO，是由FULL OPEN基金会联合顶级投资机构、社区共同成立。致力于成为WEB3.0社交+元宇宙链游价值探索者，为全球多链条生态系统提供最强大、最丰富的社交和娱乐数据，共同探索 Web3.0 世界的人与组织、关系与行为的新范式，成为 Web3.0时代 的社交、娱乐基础设施。',
  swap503:'FULL OPEN不仅会开发常规社交应用，例如聊天、语音、视频、发帖、加入社群、管理社群工具等，更是会满足社区，社群的需求开设线上会议室，随意更换场景和个人会议形象。DID 是 web3.0 身份的基础。借助 IPFS+IPNS 存储，可扩展整合 Web3.0 身份的多链数据，实现数据价值最大化。',
  swap504:'第一阶段:通过数字模型nft挖矿拓展用户',
  swap505:'第二阶段：',
  swap506:'1、上线即时通讯，提升社交用户体验 ',
  swap507:'2、开放游戏语音，增强游戏社交属性',
  swap508:'第三阶段：',
  swap509:'1、上线元宇宙链游、',
  swap510:'2，社交板块正式融合元宇宙，增加更多个人形象，会议背景选择。',
  swap511:'第一部门业绩',
  swap512:'点位人数',
  swap513:'展开',
  swap514:'收起',
  swap515:'保赋云链-Guarantee cloud chain（GCC）致力于构建全球最大的区块链云保险生态。任何风险的个体可以平等、安全、简单地获得去中心化自治组织的风险保障，在保险事故发生之时，通过智能合约自动获得赔付。基于GCC协议，开发者可以利用GCC',
  swap516:'库存',
  swap517:'高级',
  swap518:'积分专场',
  swap519:'藏品详情',
  swap520:'FULL-OPEN致力于构建全球最大的区块链云保险生态。任何风险的个体可以平等、安全、简单地获得去中心化自治组织的风险保障，在保险事故发生之时，通过智能合约自动获得赔付。基于GCC协议，开发者可以利用GCC。',
  swap521:'版权说明',
  swap522:'权益须知',
  swap523:'1、NFT为虚拟产品，非实物，仅限于实名认证且满18周岁的中国大陆用户购买。',
  swap524:'2、NFT版权作品权益须知：非实物，仅限于实名认证且满18周岁的中国大陆用户购买。',
  swap525:'3、NFT版权作品权益须知：非实物，仅限于实名认证且满18周岁的中国大陆用户购买。',
  swap526:'购买须知',
  swap527:'1、NFT为虚拟产品，非实物，仅限于实名认证且满18周岁的中国大陆用户购买。',
  swap528:'2、购买前请先确定您是本人注册且是本人身份证实名认证，手机号和本人注册证件绑定。',
  swap529:'3、NFT版权作品权益须知：非实物，仅限于实名认证且满18周岁的中国大陆用户购买。',
  swap530:'4、一经出售，概不退换。',
  swap531:'开盲盒',
  swap532:'合成活动',
  swap533:'VIP记念卡',
  swap534:'交易所',
  swap535:'购物商城',
  swap536:'NFT和产品销售奖励',
  swap537:'一，点位奖20%',
  swap538:'(团队网体20层，共104万个点位1%奖励)',
  swap539:'二，直推奖100%和40% ',
  swap540:'(直推滑落到每个点位的第一条线100%，第二，三条线40%奖励)',
  swap541:'三，财富奖15%',
  swap542:'(直推第一点位下的第二，三条线，无限裂变网体奖励，不封顶)',
  swap543:'四，培育奖12%',
  swap544:'(直推点位财富奖的80%奖励)',
  swap545:'五，等级奖13%',
  swap546:'(团队总业绩级差3%至13%奖励)',
  swap547:'六，总拨出100%',
  swap548:'(扣除10%手续费进入币池，去中心化、正拨比、无烧伤、无资金池、永不关网、安全无风险)',
  swap549:'铸造100U NFT（赠送2.5倍额度+100个FO）',
  swap550:'铸造500U NFT（赠送5倍额度+1000个FO）',
  swap551:'铸造1000U NFT（赠送7倍额度+3000个FO）',
  swap552:'联创分红池',
  swap553:'昨日新增',
  swap554:'全网手续费',
  swap555:'全网新增',
  swap556:'兑换记录',
  swap557:'我的推荐人地址',
  swap558:'大区算力',
  swap559:'小区算力',
  swap560:'SESO社区板块',
  swap561:'基础代币挖矿',
  swap562:'SESO社区先行者',
  swap563:'艺术博物馆',
  swap564:'NFT交易',
  swap565:'欢乐空间',
  swap566:'链上游戏大厅',
  swap567:'平行世界',
  swap568:'元宇宙',
  swap569:'个人中心',
  swap570:'收益',
  swap571:'支付数量',
  swap572:'账号总余额',
  swap573:'团队',
  swap574:'交易价格',
  swap575:'支付',
  swap576:'得到',
  swap577:'交易税率',
  swap578:'交易税收',

  swap579:'当前SESO社区总产出',
  swap580:'当前总销毁',
  swap581:'算力租赁',
  swap582:'矿机算力',
  swap583:'矿机价格',
  swap584:'租赁',
  swap585:'暂无租赁权限',
  swap586:'挖矿进度',
  swap587:'当前算力',
  swap588:'当前产值',
  swap589:'租赁时间',
  swap590:'静态总产出',
  swap591:'昨日静态产出',
  swap592:'动态总产出',
  swap593:'昨日动态产出',
  swap594:'绑定成功',
  swap595:"已经是可购买最低算力",
  swap596:"已经是可购买最高算力",
  swap597:"合约地址",
  swap598:"销毁合约地址",
  swap599: '支付发生错误',














};
