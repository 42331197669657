export const lang = {
  swap1: '輸出為估值，您將獲得至少{inputOutMin} {symbol},或者交易將被撤',
  swap2: '輸入為估值，您將出售最多{inputInMax} {symbol},或者交易將被撤回',
  swap3: '輸出為估值。如果價格變化超過 {slippage}%，則您的交易將被撤回',
  swap4: '輸出為估值。如果價格變化超過 {slippage}%，則您的交易將被撤回',
  swap5: '確認交換',
  swap6: '正在等待確認',
  swap7: '正在將',
  swap8: '交換為',
  swap9: '在你的錢包種確認此交易',
  swap10: '已提交交易',
  swap11: '在BscScan上查看',
  swap12: '將Swan-LP添加到Metamsak',
  swap13: '交易拒絕',
  swap14: '關閉',
  swap15: '正在移除',
  swap16: '和',
  swap17: '正在供應',
  swap18: '兌換',
  swap19: '流動性',
  swap20: '從',
  swap21: '估計',
  swap22: '餘額',
  swap23: '最大',
  swap24: '選擇通證',
  swap25: '到',
  swap26: '連接錢包',
  swap27: '此交易的流動性不足',
  swap28: '輸入數量',
  swap29: '餘額不足',
  swap30: '選擇代幣',
  swap31: '授權',
  swap32: '包裝',
  swap33: '解包',
  swap34: '價格影響過高',
  swap35: '閃兌',
  swap36: '價格',
  swap37: '滑點容差',
  swap38: '價格影響',
  swap39: '路由',
  swap40: '最小獲得量',
  swap41: '流動性供應商費用',
  swap42: '交易收據',
  swap43: '閃對',
  swap44: '您將獲得',
  swap45: '最大出售量',
  swap46: '此交換的價格影響至少為 10%,請確認此交換',
  swap47: '確認',
  swap48: '取消',
  swap49: '最近交易',
  swap50: '全部清除',
  swap51: '最近沒有交易',
  swap52: '您的流動性',
  swap53: '已入池',
  swap54: '添加',
  swap55: '移除',
  swap56: '增加流動性',
  swap57: '沒有看到您加入的池？ ',
  swap58: '導入它',
  swap59: '如果您將XXX代幣質押在農場中，請在此處取消質押以查看它們',
  swap60: '您是第一個流動性供應商。 ',
  swap61: '您添加代幣的比列，將決定該流動性池的初始價格。 ',
  swap62: '如果您對匯率滿意，請點擊“供應”以進行檢查。 ',
  swap63: '輸入',
  swap64: '兌換率和流動池份額',
  swap65: '流動性池中的份額',
  swap66: '供應',
  swap67: '接收',
  swap68: '金額',
  swap69: '你將獲得',
  swap70: '您的錢包中的LP代幣',
  swap71: '移除流動性以收回代幣',
  swap72: '導入池',
  swap73: '選擇代幣以查找您的流動性。 ',
  swap74: '您在該流動性池中還沒有提供流動性。 ',
  swap75: '已入金',
  swap76: '匯率',
  swap77: '確認供應',
  swap78: '已焚毀',
  swap79: '資產變更',
  swap80: '提幣賬單',
  swap81: '提幣數量',
  swap82: '手續費',
  swap83: '實際到賬',
  swap84: '已通過',
  swap85: '已駁回',
  swap86: '我要買',
  swap87: '我要賣',
  swap88: '訂單',
  swap89: '需求發布',
  swap90: '單價',
  swap91: '管理代幣',
  swap92: '導入代幣',
  swap93: '搜索名稱或粘貼地址',
  swap94: '導入',
  swap95: '管理Tokens',
  swap96: '自定義代幣',
  swap97: '首頁',
  swap98: '交易',
  swap99: '資訊',
  swap100: '社區',
  swap101: '我的',
  swap102: '未連接',
  swap103: '申請中',
  swap104: '進入',
  swap105: '行情展示',
  swap106: '幣種',
  swap107: '漲跌幅',
  swap108: '蝴蝶部落是⼀群有共同興趣、認知、價值觀的社區，聯合在一起互動、交流、合作、感染而產⽣的新的信任和價值連結平臺，由這種建⽴在社群之間的情感關聯、信任產生新的部落型社區。 區塊鏈時代的來臨讓社群發揮了難以想像的⼒量，通過社群和社群組織之間更有效率的連接和價值交換，形成一個⾼效率且高效益的全新運轉經濟體',
  swap109: '暫未開放',
  swap110: '蝴蝶社區',
  swap111: 'SER & SES 雙幣聯動 · 輪迴重生即將開啟權益認籌',
  swap112: '社區公告',
  swap113: '聯繫人',
  swap114: '消息',
  swap115: '圈子',
  swap116: '搜索聯繫人',
  swap117: '暫無聯繫人',
  swap118: '暫無數據',
  swap119: '屏蔽該用戶',
  swap120: '舉報該內容',
  swap121: '快轉',
  swap122: '引用',
  swap123: '分享',
  swap124: '新的朋友',
  swap125: '群組',
  swap126: '頻道',
  swap127: '附近的人和群',
  swap128: '發表',
  swap129: '請輸入發表內容',
  swap130: '只接受 JPG、BMP、PNG、GIF 格式',
  swap131: '上傳中...',
  swap132: '上傳成功',
  swap133: '上傳失敗',
  swap134: '請輸入內容',
  swap135: '請添加圖片',
  swap136: '表布成功',
  swap137: '深度',
  swap138: '快訊',
  swap139: '閃兌賬單',
  swap140: '當前餘額不足',
  swap141: '請選擇幣種',
  swap142: '兌換成功',
  swap143: '已賺取',
  swap144: '年化利率',
  swap145: '收割',
  swap146: '質押',
  swap147: '進行中',
  swap148: '已結束',
  swap149: '我參與的',
  swap150: '質押lp代幣',
  swap151: '贖回lp代幣',
  swap152: '贖回',
  swap153: '確實',
  swap154: '分享鏈接',
  swap155: '新手資料',
  swap156: '更多',
  swap157: '發圖素材',
  swap158: '常用工具',
  swap159: '常見問題',
  swap160: '市場機制',
  swap161: '發展規劃',
  swap162: '上一張',
  swap163: '下一張',
  swap164: '發圈素材',
  swap165: '發圈海報',
  swap166: '發圈視頻',
  swap167: '發圈文案',
  swap168: '市場活動',
  swap169: '節日祝福',
  swap170: '好友邀請',
  swap171: '項目介紹',
  swap172: '全部',
  swap173: '轉入',
  swap174: '轉出',
  swap175: '轉賬',
  swap176: '收款',
  swap177: '請輸入轉賬數量',
  swap178: '請輸入接收人地址',
  swap179: '轉賬成功',
  swap180: '接收人',
  swap181: '數量',
  swap182: '可轉',
  swap183: '請選擇',
  swap184: '請輸入數量',
  swap185: '最大轉賬',
  swap186: '最小轉賬',
  swap187: '交易值扣除',
  swap188: '手續費扣減',
  swap189: '請輸入要修改的暱稱',
  swap190: '保存',
  swap191: '請先上傳圖片',
  swap192: '修改成功',
  swap193: '我要反饋',
  swap194: '回复',
  swap195: '反饋標題',
  swap196: '標題',
  swap197: '請填寫標題',
  swap198: '反饋內容',
  swap199: '請填寫內容',
  swap200: '附件',
  swap201: '提交',
  swap202: '暫無回复',
  swap203: '提交成功',
  swap204: '您的問題',
  swap205: '您在使用app過程中遇見什麼問題，或者對平台有什麼建議都可以寫在這裡哦',
  swap206: '請填寫回复內容',
  swap207: '蝴蝶學院',
  swap208: '邀請好友',
  swap209: '星級中心',
  swap210: '資產',
  swap211: '可用',
  swap212: '凍結',
  swap213: '貢獻值',
  swap214: '查看礦池',
  swap215: '資產明細',
  swap216: '請先鏈接賬號',
  swap217: '去邀請',
  swap218: '我的推薦人',
  swap219: '暫無上級',
  swap220: '我的推薦',
  swap221: '暫無推薦',
  swap222: '複製成功',
  swap223: '不支持複製,該瀏覽器不支持自動複製',
  swap224: '劃轉',
  swap225: '持有礦池',
  swap226: '領取',
  swap227: '閱讀',
  swap228: '成長區',
  swap229: '贈送區',
  swap230: '首發中心',
  swap231: '蝴蝶部落是⼀群有共同興趣、認知、價值觀的社區，聯合在一起互動、交流、協作、感染而產⽣的新的信任和價值鏈接平台，由這種建⽴在社群之間的情感關聯、信任產生新的部落型社區。區塊鏈時代的來臨讓社群發揮了難以想像的⼒量，通過社群和社群組織之間更有效率的連接和價值交換，形成一個⾼效率且高效益的全新運轉經濟體。 ',
  swap232: '當前已完成進度百分比',
  swap233: '會超過100%',
  swap234: '總需捐獻',
  swap235: '您已捐獻',
  swap236: '說明',
  swap237: '本次xxxx領袖招募信息由xxxx提供並負責',
  swap238: '本次招募可超額進行，由xxxx社區進行考核，未予通過的領袖席位將自動退回捐獻資金、',
  swap239: '會員',
  swap240: '聯合合夥人',
  swap241: '股東合夥人',
  swap242: '恭喜您，您已獲得',
  swap243: '列表',
  swap244: '進行中',
  swap245: '已完成',
  swap246: '次數',
  swap247: '總產能',
  swap248: '號礦池',
  swap249: '號贈送',
  swap250: '總獎勵',
  swap251: '消耗',
  swap252: '完成次數',
  swap253: '已獎勵',
  swap254: '剩餘次數',
  swap255: '充值',
  swap256: '提現',
  swap257: '複製',
  swap258: '請輸入充值數量',
  swap259: '請輸入提現數量',
  swap260: '平台餘額',
  swap261: '充值成功',
  swap262: '轉賬失敗',
  swap263: '提現數量不可可用餘額',
  swap264: '當前不可提幣',
  swap265: '超過當前最大可提幣量',
  swap266: '提幣成功',
  swap267: '總資產',
  swap268: '代幣',
  swap269: '成交',
  swap270: '成交率',
  swap271: '限額',
  swap272: '去購買',
  swap273: '去出售',
  swap274: '請輸入購買數量',
  swap275: '購買數量須在限額內',
  swap276: '超過餘額',
  swap277: '購買成功',
  swap278: '出售成功',
  swap279: '我的訂單',
  swap280: '可用餘額',
  swap281: '請輸入出售數量',
  swap282: '剩餘可成交',
  swap283: '立即購買',
  swap284: '立即出售',
  swap285: '我的發布',
  swap286: '發布訂單',
  swap287: '發布類型',
  swap288: '購買',
  swap289: '出售',
  swap290: '支付方式',
  swap291: '請選擇支付方式',
  swap292: '請輸入單價',
  swap293: '發布數量',
  swap294: '請輸入發布數量',
  swap295: '選擇幣種',
  swap296: '最大限額',
  swap297: '請輸入最大限額',
  swap298: '最小限額',
  swap299: '請輸入最小限額',
  swap300: '發布',
  swap301: '輸入最大價格應為',
  swap302: '最小價格應為',
  swap303: '輸入最大數量應為',
  swap304: '最小數量應為',
  swap305: '買',
  swap306: '賣',
  swap307: '訂單號',
  swap308: '取消訂單',
  swap309: '撤銷成功',
  swap310: '單價不在範圍內',
  swap311: '數量不在範圍內',
  swap312: '請選擇發布幣種',
  swap313: '請選擇最小限額',
  swap314: '請選擇最大限額',
  swap315: '最小限額不得大於最大限額',
  swap316: '最大限額不能超過發布數量',
  swap317: '購買價格不能超過餘額',
  swap318: '發布數量不能超過餘額',
  swap319: '發布成功',
  swap320: '提幣',
  swap321: '你已加入',
  swap322: '加入',
  swap323: '請先登錄',
  swap324: '授權失敗',
  swap325: '加入成功',
  swap326: '實際兌換數量',
  swap327: '收到數量',
  swap328: '請輸入兌換數量',
  swap329: '請先添加邀請人',
  swap330: '升级',
  swap331: 'SER是DEFI領域基於DAO+ iGaming 3.0的生態型代幣，子幣SES。 通過全新的雙幣聯動的市場推動計畫，給予用戶娛樂性體驗的同時保障價值的持續穩定增長。 浴火涅盤、輪回重生、生生不息，價值不滅，SER權益認籌啟動中！',
  swap332: '我的獎勵',
  swap333: '推薦列表',
  swap334: '地址',
  swap335: '確定將',
  swap336: '劃轉到資產嗎',
  swap337: '礦池賬戶',
  swap338: '錢包賬戶',
  swap339: '貢獻值餘額',
  swap340: '消耗貢獻值',
  swap341: '確認劃轉',
  swap342: '增加貢獻值',
  swap343: '轉入成功',
  swap344: '貢獻值不足',
  swap345: '轉出成功',
  swap346: '當前暫無可用餘額',
  swap347: '已超過最大持有',
  swap348: '即將開放',
  swap349: '需支付',
  swap350: '将得到',
  swap351: '邀請獎勵計劃',
  swap352: '邀請用戶共同助力Nebula基礎設施建設，成為Nebula共同建設者，即可獲得多重收益。',
  swap353: '我的邀請鏈接',
  swap354: '礦機列表',
  swap355: '持有列表',
  swap356: '持有數量',
  swap357: '總產出',
  swap358: '今日產出',
  swap359: '產出進度',
  swap360: '礦機',
  swap361: '預計產出',
  swap362: '充值數量',
  swap363: '對方賬戶',
  swap364: '請輸入對方賬戶',
  swap365: '轉賬數量',
  swap366: '轉賬說明',
  swap367: '內部互轉不走區塊，不扣手續費，即時到賬，不可逆請仔細核對對方賬戶。 ',
  swap368: '需要轉到其他平台請通過提幣方式',
  swap369: '超過可成交數',
  swap370: '盲盒',
  swap371: '我的資產',
  swap372: '帳單清單',
  swap373: '請綁定邀請人',
  swap374: '發單總價需大於30USDT',
  swap375: '超出了錢包餘額',
  swap376: '入帳中，請稍後查看',
  swap377: '新用戶優惠',
  swap378: '----首次注册即可獲贈價值10 USDT HM',
  swap379: '掛單',
  swap380: '錢包地址',
  swap381: '挖礦',
  swap382: '分紅',

  swap383: '公告',
  swap384: '暫無公告',
  swap385: '賬單',
  swap386: '獎勵類型',
  swap387: '邀請得豐厚獎勵',
  swap388: '我的團隊',
  swap389: '充值資金',
  swap390: '提取資金',
  swap391: 'NFT背包',
  swap392: '用戶反饋',
  swap393: '關於我們',
  swap394: '儀錶盤',
  swap395: '挖礦中',
  swap396: '算力',
  swap397: '取回',
  swap398: '平台算力',
  swap399: '我的算力',
  swap400: '團隊算力',
  swap401: 'NFT市場',
  swap402: '售價',
  swap403: '開始',
  swap404: '我的碎片',
  swap405: '合成',
  swap406: '說明：點擊碎片，滿五個即可開啟合成，隨機獲得 一張NFT或者一個碎片',
  swap407: '合成中',
  swap408: '贈送',
  swap409: '合成成功',
  swap410: '請輸入贈送地址',
  swap411: '說明：贈送後無法撤銷',
  swap412: '確定',
  swap413: '購買中',
  swap414: '贈送成功',
  swap415: '碎片數量不足',
  swap416: '歌曲展示',
  swap417: '展示',
  swap418: '簡介',
  swap419: '合作夥伴',
  swap420: '我的等級',
  swap421: '當前等級',
  swap422: '團隊業績',
  swap423: '達標部門',
  swap424: '好友邀請計劃',
  swap425: '邀請好友加入SESO社区，一起賺取豐厚獎勵！如果您邀請的朋友參與遊戲，您將獲得多重收益',
  swap426: '團隊人數',
  swap427: '複製邀請',
  swap428: '業績',
  swap429: '兌換數量',
  swap430: '反饋列表',
  swap431: '暫無反饋',
  swap432: '用戶',
  swap433: '線上客服',
  swap434: '暫無答复',
  swap435: '添加圖片（最多三張）',
  swap436: '請輸入留言',
  swap437: '反饋',
  swap438: '請上傳圖片',
  swap439: '請輸入反饋內容',
  swap440: '已持有',
  swap441: '邀請',
  swap442: '推薦鏈接',
  swap443: '請綁定領袖',
  swap444: '了解',
  swap445: '治理',
  swap446: '競猜',
  swap447: '請先綁定邀請人',
  swap448: '取消成功',
  swap449: '類型',
  swap450: '市场',
  swap452: '重置',
  swap453: '碎片',
  swap454: '購買類型',
  swap455: '平臺公告',
  swap456: '收貨地址',
  swap457: 'FULL OPEN簡介',
  swap458: 'FULL OPEN簡稱FO，是由FULL OPEN基金會聯合頂級投資機構、社區共同成立。 致力於成為WEB3.0社交+元宇宙鏈遊價值探索者，為全球多鏈條生態系統提供最强大、最豐富的社交和娛樂數據，共同探索Web3.0世界的人與組織、關係與行為的新範式，成為Web3.0時代的社交、娛樂基礎設施。 FULL OPEN不僅會開發常規社交應用，例如聊天、語音、視頻、發帖、加入社群、管理社群工具等，更是會滿足社區，社群的需求開設線上會議室，隨意更換場景和個人會議形象。 DID是web3.0身份的基礎。 借助IPFS+IPNS存儲，可擴展綜合Web3.0身份的多鏈數據，實現數據價值最大化。 第一階段、通過數位模型nft挖礦拓展用戶第二階段、1、上線即時通訊，提升社交用戶體驗2、開放遊戲語音，增强遊戲社交内容第三階段、1、上線元宇宙鏈遊、2，社交板塊正式融合雲宇宙，增加更多個人形象，會議背景選擇。',
  swap459: '權益池',
  swap460: '超級合夥人招募',
  swap461: '會員招募',
  swap462: '超級合夥人權益',
  swap463: '手續費累計獎勵',
  swap464: '社區累計獎勵',
  swap465: '手續費分紅',
  swap466: 'NFT數藏',
  swap467: '鏈遊大廳',
  swap468: '匿名社交',
  swap469: 'AI云計算',
  swap470: '會員招募計畫',
  swap471: '塔蘇大老窖（9~10年珍藏）',
  swap472: '逸春風牡蠣酒1062',
  swap473: '購買份數',
  swap474: '超級合夥人招募計畫',
  swap475: '逸春風519.逸春風重陽酒各一支',
  swap476: '收貨人',
  swap477: '手機號碼',
  swap478: '所在地區',
  swap479: '所在地區（詳細）',
  swap480: '請輸入收貨人',
  swap481: '請輸入手機號碼',
  swap482: '請輸入所在地區',
  swap483: '請輸入詳細地區',
  swap484: '下一等級達標條件',
  swap485: '請先購買',
  swap486: '無',
  swap487: '今日業績',
  swap488: '大廳',
  swap489: '獎勵進度',
  swap490: '獎勵',
  swap491: '發行量',
  swap492: '開始時間',
  swap493: '結束時間',
  swap494: '我的資金',
  swap495: '我的藏品',
  swap496: '敬請期待',
  swap497: '暫無購買資格',
  swap498: '平臺簡介',
  swap499: '發展',
  swap500: 'NFT',
  swap501: '發展',
  swap502: 'FULL OPEN簡稱FO，是由FULL OPEN基金會聯合頂級投資機構、社區共同成立。 致力於成為WEB3.0社交+元宇宙鏈遊價值探索者，為全球多鏈條生態系統提供最强大、最豐富的社交和娛樂數據，共同探索Web3.0世界的人與組織、關係與行為的新範式，成為Web3.0時代的社交、娛樂基礎設施。',
  swap503: 'FULL OPEN不僅會開發常規社交應用，例如聊天、語音、視頻、發帖、加入社群、管理社群工具等，更是會滿足社區，社群的需求開設線上會議室，隨意更換場景和個人會議形象。 DID是web3.0身份的基礎。 借助IPFS+IPNS存儲，可擴展綜合Web3.0身份的多鏈數據，實現數據價值最大化。',
  swap504: '第一階段:通過數位模型nft挖礦拓展用戶',
  swap505: '第二階段：',
  swap506: '1、上線即時通訊，提升社交用戶體驗',
  swap507: '2、開放遊戲語音，增强遊戲社交内容',
  swap508: '第三階段：',
  swap509: '1、上線元宇宙鏈遊、',
  swap510: '2，社交板塊正式融合元宇宙，新增更多個人形象，會議背景選擇。',
  swap511: '第一部門業績',

  swap512: '點位人數',
  swap513: '展開',

  swap514: '收起',
  swap515: '保賦雲鏈-Guarantee cloud chain（GCC）致力於構建全球最大的區塊鏈雲保險生態。 任何風險的個體可以平等、安全、簡單地獲得去中心化自治組織的風險保障，在保險事故發生之時，通過智慧合約自動獲得賠付。 基於GCC協定，開發者可以利用GCC',
  swap516: '庫存',
  swap517: '高級',
  swap518: '積分專場',
  swap519: '藏品詳情',
  swap520: 'FULL-OPEN致力於構建全球最大的區塊鏈雲保險生態。 任何風險的個體可以平等、安全、簡單地獲得去中心化自治組織的風險保障，在保險事故發生之時，通過智慧合約自動獲得賠付。 基於GCC協定，開發者可以利用GCC。',
  swap521: '版權說明',
  swap522: '權益須知',
  swap523: '1、NFT為虛擬產品，非實物，僅限於實名認證且滿18周歲的中國大陸用戶購買。',
  swap524: '2、NFT版權作品權益須知：非實物，僅限於實名認證且滿18周歲的中國大陸用戶購買。',
  swap525: '3、NFT版權作品權益須知：非實物，僅限於實名認證且滿18周歲的中國大陸用戶購買。',
  swap526: '購買須知',
  swap527: '1、NFT為虛擬產品，非實物，僅限於實名認證且滿18周歲的中國大陸用戶購買。',
  swap528: '2、購買前請先確定您是本人注册且是本人身份證實名認證，手機號和本人注册證件綁定。',
  swap529: '3、NFT版權作品權益須知：非實物，僅限於實名認證且滿18周歲的中國大陸用戶購買。',
  swap530: '4、一經出售，概不退換。',
  swap531: '開盲盒',
  swap532: '合成活動',
  swap533: 'VIP紀念卡',
  swap534: '交易所',
  swap535: '購物商城',
  swap536: 'NFT和產品銷售獎勵',
  swap537: '一，點位獎勵20%',
  swap538: '(團網體20層，共104萬點位1%獎勵)',
  swap539: '二，直接獎勵100%和40%',
  swap540: '(直接推滑落到每個點的第一條線100%，第二，第三條線40%獎勵)',
  swap541: '三，財富獎勵15%',
  swap542: '(直推第一位下的第二，三條線，無限裂變網體獎勵，不封頂)',
  swap543: '四，教育獎12%',
  swap544: '(直接推薦位財富獎勵的80%獎勵)',
  swap545: '五，等獎13%',
  swap546: '(團隊總成績等級差3%至13%獎勵)',
  swap547: '六，總撥出100%',
  swap548: '(扣除10%手動費用進入幣池，去中心化、正撥比、無燒傷、無資金池、永不關網、安全無風險)',
  swap549: '鑄造100U NFT（贈送2.5倍額度+100個FO）',
  swap550: '鑄造500U NFT（贈送5倍額度+1000個FO）',
  swap551: '鑄造1000U NFT（贈送7倍額度+3000個FO）',
  swap552: '聯創分紅池',
  swap553: '昨日新增',
  swap554: '全網手續費',
  swap555: '全網新增',
  swap556:'兌換記錄',
swap557:'我的推薦人地址',
swap558:'大區算力',
swap559:'社區算力',
swap560:'SESO社区板塊',

swap561:'基礎代幣挖礦',
swap562:'SESO社区先行者',
swap563:'藝術博物館',
swap564:'NFT交易',
swap565:'歡樂空間',
swap566:'鏈上遊戲大廳',
swap567:'平行世界',
swap568:'元宇宙',
swap569:'個人中心',
swap570:'收益',
swap571:'支付數量',
swap572:'帳號總餘額',
swap573:'團隊',
swap574:'交易價格',
  swap575:'支付',
  swap576:'得到',
  swap577:'交易稅率',
  swap578:'交易稅收',
  swap579:'當前SESO社区總產出',
swap580:'當前總銷毀',
swap581:'算力租賃',
swap582:'礦機算力',
swap583:'礦機價格',
swap584:'租賃',
swap585:'暫無租賃許可權',
swap586:'挖礦進度',
swap587:'當前算力',
swap588:'當前產值',
swap589:'租賃時間',
swap590:'靜態總產出',
swap591:'昨日靜態產出',
swap592:'動態總產出',
swap593:'昨日動態產出',
swap594:'綁定成功',
swap595:"已經是可購買最低算力",
swap596:"已經是可購買最高算力",
swap597:"合約地址",
swap598:"銷毀合約地址",
swap599: '支付發生錯誤',
};
