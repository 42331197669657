<template>
  <div class="invite">
    <div class="head">
      <img @click="back" src="@/assets/images/back.png" class="arrow_img" />
      <span>公告列表</span>
    </div>
    <van-list v-model="loading" :finished="finished" :finished-text="finishText" @load="onLoad">
      <div class="notice" v-for="(item, index) in list" :key="index" @click="navigatorTo('bulletinDetail', item.id)">
        <img class="notice_img" :src="baseUrl + item.img_url" alt="" v-if="item.img_url" />
        <div class="right">
          <span>{{ item.title }}</span>
          <span class="time_text">{{ dateFormatFn(item.createtime) }}</span>
        </div>
      </div>
    </van-list>
  </div>
</template>
  
<script>
import { homeApi } from '@/api/index';
import { dateFormat } from '@/utils/formatTool.js';
export default {
  data() {
    return {
      list: [],
      page: 0,
      loading: false,
      finished: false,
      total: 1,
      baseUrl: 'https://api.gnamege.com',
      finishText: '加载更多',
    };
  },
  mounted() {
    this.onLoad();
    window.addEventListener('scroll', this.scrollBottom);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollBottom);
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    scrollBottom() {
      //加载更多
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight; //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight; //滚动条到底部的条件
      console.log(scrollTop, 'scrollTop');
      console.log(windowHeight, 'windowHeight');
      console.log(scrollHeight, 'scrollHeight');
      if (scrollTop + windowHeight >= scrollHeight && scrollTop > 0) {
        //写后台加载数据的函数
        console.log('滚动到底部啦');
        if (this.finished) {
          this.loading = true; // 关闭加载状态
          this.finished = false; // 加载开始
          this.finishText = '加载更多';
          this.onLoad();
        }
      } else {
        // this.loading = false; // 关闭加载状态
      }
    },
    navigatorTo(name, id) {
      this.$router.push({ name, query: { id: id } });
    },
    dateFormatFn(date) {
      return dateFormat(new Date(date * 1000), 'yyyy-MM-dd');
    },
    getAnnouncement() {
      let params = {
        page: this.page,
        query: {},
      };
      homeApi.getAnnouncement(params).then(res => {
        if (res.code == 200) {
          let rows = res.data.rows;
          // 如果返回的数组是空或数组长度是0
          if (rows == null || rows.length === 0) {
            this.loading = false; // 关闭加载状态
            this.finished = true; // 加载结束
            this.finishText = '没有更多了';
            if (this.list.length <= 0) {
              this.total = 0;
            }
            return;
          }
          this.loading = false; // 关闭加载状态
          this.finishText = '上滑加载更多';
          this.finished = true;
          this.total = res.data.total; //数据总条数
          this.list = this.list.concat(rows); //合并数组
          console.log(this.list);

          // 如果合并之后的数组长度大于返回的数据总条数
          if (this.list.length >= this.total) {
            this.finished = true; // 加载结束
            this.finishText = '没有更多了';
          }
        } else {
          this.total = 0;
          this.loading = false; // 关闭加载状态
          this.finished = true; // 加载结束
          this.finishText = '没有更多了';
        }
      });
    },
    onLoad() {
      this.page += 1;
      this.getAnnouncement();
    },
  },
};
</script>
  
<style lang="less" scoped>
.invite {
  min-height: calc(100vh + 50px);
  padding: 66px 16px;
  .head {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(#000, 0.8);
    letter-spacing: 1.5px;
    font-weight: 550;
    background: #fff;
    padding: 14px 0;
    z-index: 99999;
    .arrow_img {
      position: absolute;
      left: 20px;
      width: 10px;
      height: 16px;
    }
  }
  .notice {
    display: flex;
    color: #000;
    padding: 16px;
    border-radius: 10px;
    margin-bottom: 16px;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0, rgba(255, 255, 255, 0.3) 0, rgba(255, 255, 255, 0.3) 100%, rgba(255, 255, 255, 0.3) 100%);
    &_img {
      width: 100px;
      height: 68px;
      border-radius: 10px;
      margin-right: 10px;
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 550;

      .time_text {
        font-weight: normal;
        font-size: 13px;
        padding-top: 10px;
      }
    }
  }
}
</style>